<template lang="pug">
.permissions
    v-row
        v-col
            v-card(light, flat)
                v-card-title
                    span Permissions
                    v-icon(small, title='Edit a user\'s permissions, or add new users.') info
                v-card-subtitle.d-flex
                    v-text-field(v-model='searchText', prepend-inner-icon='search', label='Search', single-line, hide-details, clearable, clear-icon='clear', color='brand', outlined, dense, light, solo, rounded, flat)
                    v-btn.white--text.ml-4(@click='showNewUserDialog', color='brand') Add User

    v-row
        v-col
            v-data-table(:headers='columns', :items='users', dense, show-expand, @click:row='(item, slot) => rowClicked(item, slot)', fixed-header, light, disable-pagination, hide-default-footer, single-expand, :search='searchText', height='80vh')
                template(v-slot:expanded-item='{headers, item}')
                    td(:colspan='headers.length')
                        v-container
                            template(v-for='category in editingPermissions')
                                v-row
                                    v-col.pt-0
                                        v-checkbox(:label='category.name', v-model='category.accessLevel', color='brand', :disabled='isCurrentUser && category.role === "admin"', :key='category.role', :title='category.description', hide-details)
                                v-row.ml-6
                                    v-col(v-for='child in category.children', :key='child.role')
                                        v-checkbox(:label='child.name', color='brand', v-model='child.accessLevel', :disabled='!category.accessLevel', :title='child.description', dense)
                                v-divider
                            v-row.py-4
                                v-spacer
                                v-btn.white--text.mr-2(small, color='red', @click='deleteUser(item.email)', v-if='!isCurrentUser') Delete User
                                v-btn.white--text(small, color='green', @click='savePermissions(item.email)') Save

    v-dialog(v-model='newUserDialog', max-width='1000')
        v-card(light)
            v-card-title Add new user
            v-card-text
                v-form(ref='newUserForm')
                    v-text-field(v-model='newUserName', dense, label='Add User', color='brand', suffix='@dorsettcontrols.com', single-line, @keyup='handleEmailKeyUp', :error-messages='newUserErrors')
                    template(v-for='category in editingPermissions')
                        v-row(dense)
                            v-col
                                v-checkbox(:label='category.name', v-model='category.accessLevel', color='brand', :disabled='isCurrentUser && category.role === "admin"', :key='category.role', :title='category.description', hide-details)
                        v-row.ml-6(dense)
                            v-col(v-for='child in category.children', :key='child.role')
                                v-checkbox(:label='child.name', color='brand', v-model='child.accessLevel', :disabled='!category.accessLevel', :title='child.description', dense)
                        v-divider
                    v-row.pt-6
            v-card-actions
                v-spacer
                v-btn.white--text(color='green', @click='savePermissions(newUserEmail, true)', :disabled='newUserErrors.length > 0 || newUserName === ""') Add

    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}

</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'admin',
            message: '',
            busy: false,

            columns: [
                {
                    text: 'First Name',
                    value: 'firstName'
                },
                {
                    text: 'Last Name',
                    value: 'lastName'
                },
                {
                    text: 'Email',
                    value: 'email'
                }
            ],
            editingPermissions: null,
            editingUser: '',
            permissions: [],
            users: [],
            newUserDialog: false,
            searchText: null,
            permissionsTemplate: [
                {
                    name: 'Admin',
                    role: 'admin',
                    accessLevel: 0,
                    description: 'A DCCS admin has access to everything including assigning permissions for the entire central server.',
                    children: []
                },
                {
                    name: 'Projects',
                    role: 'projects',
                    accessLevel: 0,
                    description: 'Access to Project tools on Central server.',
                    children: [{
                        name: 'Jonas Tools',
                        role: 'projectsjonas',
                        description: 'Manipulate reports from Jonas.',
                        accessLevel: 0,
                    },
                    {
                        name: 'InfoScan Export',
                        role: 'infoscanexport',
                        description: 'Export SAT Project Data',
                        accessLevel: 0
                    }]
                },
                {
                    name: 'SAT',
                    role: 'sat',
                    accessLevel: 0,
                    description: 'Sales Automation Tool',
                    children: [{
                        name: 'Panels',
                        role: 'satpanels',
                        description: 'Adding, removing, and editing which parts are panels.',
                        accessLevel: 0,
                    },
                    {
                        name: 'Controllers',
                        role: 'satcontrollers',
                        description: 'Manage Dorsett controllers and controller data.',
                        accessLevel: 0
                    },
                    {
                        name: 'Labor Rates',
                        role: 'satlabor',
                        description: 'Ability to edit labor rates.',
                        accessLevel: 0,
                    },
                    {
                        name: 'Labor Types',
                        role: 'satlabortypes',
                        description: 'Ability to add labor categories.',
                        accessLevel: 0,
                    },
                    {
                        name: 'Users',
                        role: 'satusers',
                        description: 'Ability to manage SAT users.',
                        accessLevel: 0,
                    },
                    {
                        name: 'Escalation Rules',
                        role: 'satescalationrules',
                        description: 'Ability to edit escalation rules.',
                        accessLevel: 0
                    },
                    {
                        name: 'Download',
                        role: 'satdownload',
                        description: 'Download Sales Automation Tool.',
                        accessLevel: 0
                    },
                    {
                        name: 'Version Manager',
                        role: 'satversions',
                        description: 'Manage release channels and build versions.',
                        accessLevel: 0
                    },
                    {
                        name: 'Part Groups',
                        role: 'satpartgroups',
                        description: 'Assign parts to different groups for easy selection.',
                        accessLevel: 0
                    },
                    {
                        name: 'Bonds',
                        role: 'satbonds',
                        description: 'Ability to edit bond settings.',
                        accessLevel: 0
                    },
                    {
                        name: 'System Tags',
                        role: 'satsystemtags',
                        description: 'Manage System Tags.',
                        accessLevel: 0
                    },
                    {
                        name: 'Markup',
                        role: 'satmarkup',
                        description: 'Ability to edit sales markup defaults and limits.',
                        accessLevel: 0
                    },
                    {
                        name: 'Licensing',
                        role: 'licensing',
                        description: 'Ability to edit licensing costs.',
                        accessLevel: 0
                    },
                    {
                        name: 'Parts',
                        role: 'satparts',
                        description: 'Ability to edit some part data.',
                        accessLevel: 0
                    },
                    {
                        name: 'MML',
                        role: 'satmmls',
                        description: 'Ability to edit MMLs',
                        accessLevel: 0
                    }]
                },
                {
                    name: 'DevOps',
                    role: 'devops',
                    accessLevel: 0,
                    description: 'DevOps',
                    children: [
                        {
                            name: 'Versions',
                            role: 'devopsversions',
                            description: 'View customer versions.',
                            accessLevel: 0,
                        },
                        {
                            name: 'Update',
                            role: 'devopsupdate',
                            description: 'Update customer branches.',
                            accessLevel: 0,
                        },
                        {
                            name: 'Customers',
                            role: 'devopscustomers',
                            description: 'Manage customers.',
                            accessLevel: 0
                        },
                        {
                            name: 'Users',
                            role: 'devopsusers',
                            description: 'Manage portal users.',
                            accessLevel: 0
                        },
                        {
                            name: 'Customer Communications',
                            role: 'devopscomms',
                            description: 'Send emails to customers',
                            accessLevel: 0
                        },
                        {
                            name: 'Infoscan Client Download',
                            role: 'devopsdownloadclient',
                            description: 'Download the Infoscan client',
                            accessLevel: 0
                        },
                        {
                            name: 'Training Resources',
                            role: 'customertraining',
                            description: 'Manage portal training resources',
                            accessLevel: 0
                        },
                        {
                            name: 'Dependencies',
                            role: 'devopsdependencies',
                            description: 'Manage software dependencies.',
                            accessLevel: 0
                        }
                    ]
                },
                {
                    name: 'Analytics',
                    role: 'analytics',
                    accessLevel: 0,
                    description: 'Analytics Maint.',
                    children: [
                        {
                            name: 'Tasks',
                            role: 'analytics-tasks',
                            description: 'Manage Tasks',
                            accessLevel: 0
                        },
                        {
                            name: 'UOM',
                            role: 'analytics-uom',
                            description: 'Manage Units Of Measure and Conversion Factors.',
                            accessLevel: 0
                        },
                        {
                            name: 'Queue',
                            role: 'analytics-queue',
                            description: 'View Task Queue.',
                            accessLevel: 0
                        }
                    ]
                }

            ],
            newUserName: '',
            newUserErrors: [],
            emailRegEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/,

        };
    },
    methods: {
        getPermissionsData () {
            this.socketEmit('getPermissions', null, ({permissions, users}) => {
                this.users = users;
                this.permissions = permissions;
            });
        },
        loadPermissions (user) {
            this.editingUser = user;
            this.editingPermissions = _.clone(this.permissionsTemplate);
            this.editingPermissions.forEach(category => {
                category.accessLevel = this.permissions.find(perm => perm.email === user && perm.accessKey === category.role)?.accessLevel || 0;
                category.children.forEach(child => {
                    child.accessLevel = this.permissions.find(perm => perm.email === user && perm.accessKey === child.role)?.accessLevel || 0;
                });
            });
            return;
        },
        async savePermissions (userEmail, isNewUser = false) {
            /**
             * Returns the permissions object to update, or a new object if it doesn't exist.
             *
             * @param {string} user
             * @param {string} role
             */
            const findPermissionsItem = (userEmail, role) => {
                let perm = this.permissions.find(p => p.accessKey === role && p.email === userEmail);
                if (!perm) {
                    perm = {
                        email: userEmail,
                        accessKey: role,
                        accessLevel: 0,
                        id: dti.makeuuid()
                    };
                }
                return perm;
            };
            const permissions = [];
            this.editingPermissions.forEach(category => {
                let perm = findPermissionsItem(userEmail, category.role);
                permissions.push(perm);
                perm.accessLevel = category.accessLevel;
                category.children.forEach(child => {
                    let perm = findPermissionsItem(userEmail, child.role);
                    perm.accessLevel = child.accessLevel;
                    permissions.push(perm);
                });
            });
            console.log(`saving permissions for "${userEmail}"`);
            permissions.forEach(p => console.log(`\t${p.accessKey}: ${p.accessLevel}`));
            let results = await this.sendCommand({
                action: 'updatePermissions',
                parameters: permissions
            });
            if (results) {
                this.message = `Permissions updated for "${userEmail}"`;
                if (isNewUser) {
                    this.permissions.push(...permissions);
                    this.$refs.newUserForm.reset();
                }
            } else {
                this.message = `Failed to update permissions for "${userEmail}"`;
            }
            if (isNewUser) {
                this.getPermissionsData();
                this.newUserDialog = false;
            }
        },
        handleEmailKeyUp () {
            this.newUserErrors = this.validateEmail(this.newUserName);
        },
        validateEmail (email = this.newUserName) {
            if (!email) {
                return [];
            }

            let ret = [];
            if (!email.match(this.emailRegEx)) {
                ret = ['Invalid Email'];
            } else if (this.users.includes(email+'@dorsettcontrols.com')) {
                ret = ['Email already exists'];
            }
            return ret;
        },
        /**
         * Removes a devops (Dorsett) user.
         *
         * @param {String} userEmail - email of the user to be deleted
         */
        async deleteUser (userEmail) {
            const result = await this.sendCommand({
                action: 'removeDevopsUser',
                parameters: userEmail
            });
            if (result) {
                this.getPermissionsData();
                this.message = 'User deleted';
            } else {
                this.message = 'Error deleting user';
            }
        },

        /**
         * Return the first and last name of the user (or the email if the name isn't available)
         *
         * @param {object} user
         * @param {string} user.firstName
         * @param {string} user.lastName
         * @param {string} user.email
         */
        getDisplayName (user) {
            if (user.firstName && user.lastName) {
                return `${user.firstName} ${user.lastName}`;
            }
            return user.email;
        },
        /**
         * Handles the row:click event. Expands the row and fetches permissions if a row is being expanded.
         *
         * @param {object} user user object
         * @param {object} slot Vue slot (expanded item)
         */
        rowClicked (user, slot) {
            if (!slot.isExpanded) {
                this.loadPermissions(user.email);
            }
            slot.expand(!slot.isExpanded);
        },
        /**
         * Opens the new user dialog.
         */
        showNewUserDialog () {
            this.loadPermissions('');
            this.newUserDialog = true;
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
        isCurrentUser () {
            // don't let the user remove their own admin priveledges
            const currentUser = this.$store.state.dorsettUser;
            if (currentUser) {
                return currentUser.email === this.editingUser;
            }
            return false;
        },
        newUserEmail () {
            return this.newUserName + '@dorsettcontrols.com';
        }
    },
    watch: {

    },
    mounted () {
        // get all permissions
        this.getPermissionsData();
        window.permissionsvm = this;
    }
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
